import { Jumbotron } from 'react-bootstrap';
import React from 'react';

const NotFound = () => (
  <Jumbotron className="vertical-center">
    <h1 className="justify-content-center mx-auto">Page Not Found</h1>
  </Jumbotron>
);

export default NotFound;
