import { Col, Container, Form, Row } from 'react-bootstrap';
import React, { useState, useEffect } from 'react';

import { withFirebase } from '../Firebase';
import ProfileImage from '../ProfileImage';

import './index.css';

const UserSearch = ({ firebase, profile, onSelect }) => {

  const [query, setQuery] = useState('');
  const [users, setUsers] = useState([]);

  useEffect(() => {
    if (query.length > 0) {
      firebase
        .findUser(query)
        .then(response => {
          if (response) {
            setUsers(response.filter(profile => profile && profile.alias !== null && profile.alias.length > 0));
          } else {
            setUsers([]);
          }
        })
        .catch(error => console.log(error));
    } else {
      setUsers([])
    }
  }, [query]);

  return (
    <Container>
      <Row>
        <Col>
          <Form>
            <Form.Control type="text" placeholder="Search user" onChange={evt => setQuery(evt.target.value)} />
          </Form>
        </Col>
      </Row>
      <Row>
        <Col className="user-results">
          {users.map(user => <User key={user.user_id} profile={user} onClick={() => onSelect(user)} />)}
        </Col>
      </Row>
    </Container>
  );
}

const User = ({ profile, onClick }) => {
  return (
    <Container className="user p-0 mt-3" fluid onClick={onClick}>
      <Row noGutters>
        <Col xs={2}>
          <ProfileImage size={48} profile={profile} />
        </Col>
        <Col className="ml-4 pl-1">
          <p className="mb-0">{profile.alias}</p>
          {profile?.location?.city && <p className="mb-0 text-muted">{profile?.location?.city}, {profile?.location?.state}</p>}
        </Col>
      </Row>
    </Container>
  );
}

export default withFirebase(UserSearch);