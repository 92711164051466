import './index.css';

import {
  Button,
  Card,
  Col,
  Container,
  Jumbotron,
  Row,
  Spinner
} from 'react-bootstrap';
import { SocialIcon } from 'react-social-icons';
import { isAndroid } from 'react-device-detect';
import { useParams } from 'react-router-dom';
import React, { useState, useEffect } from 'react';

import { withFirebase } from '../Firebase';
import ProfileImage from '../ProfileImage';

const Profile = ({ firebase }) => {
  const { alias } = useParams();
  const storeUrl = isAndroid ? 'https://play.google.com/store/apps/details?id=com.kingsagemusic.ArtistConnect&hl=en_US&pageId=none' : 'https://apps.apple.com/us/app/artist-connect/id1203809730';

  const [profile, setProfile] = useState(null);
  const [genres, setGenres] = useState({});

  useEffect(() => {
    (async () => {
      try {
        const genresSnapshot = await firebase.db.ref('genres').once('value');
        setGenres(genresSnapshot.val());
        const userIdSnapshot = await firebase.db.ref(`index/alias/${alias}`).once('value');
        const userId = userIdSnapshot.val();
        const profileSnapshot = await firebase.profile(userId).once('value');
        setProfile(profileSnapshot.val());
      } catch (error) {
        alert(error);
      }
    })();
  }, []);

  const userGenres = [... new Set((profile?.services || []).map((s) => s.genre_id))].map((id) => genres[id].name);

  return (
    <Container>
      <Row className="my-4">
        <Col>
          {profile && <ProfileImage profile={profile} size={86} className="avatar d-block" />}
        </Col>
      </Row>
      <Row>
        <Card className="shadow mx-auto profile">
          {profile && <Card.Body>
            <Container className="p-0" fluid style={{ marginBottom: '12px' }}>
              <Row>
                <Col className="ml-4 pl-1">
                  <p className="mb-1"><span className="text-muted h5">Book</span> <strong className="h3 mx-1">{profile.alias}</strong> <span className="text-muted h5">on Artist Connect</span></p>
                  <p className="mb-2 text-muted">{userGenres.join(", ")}</p>
                  <p className="mb-0 text-muted"><small>{profile.bio}</small></p>
                  {profile.website && <p className="mb-0 text-muted"><small><a target="_parent" href={profile.website}>Website</a></small></p>}
                </Col>
              </Row>
            </Container>
          </Card.Body>}
          {!profile && <Spinner
            className="justify-content-center mx-auto my-4"
            style={{ width: '2rem', height: '2rem' }}
            variant="primary" animation="border" />}
        </Card>
      </Row>
      {profile && <Row className="mt-4">
        <Col>
          <div className="text-center" style={{ marginLeft: '8px' }}>
            {profile.soundcloud_url && <SocialIcon target="_parent" url={profile.soundcloud_url} />}
            {profile.youtube_url && <SocialIcon target="_parent" url={profile.youtube_url} />}
            {profile.spotify_profile_url && <SocialIcon target="_parent" url={profile.spotify_profile_url} />}
            {profile.instagram_session && profile.instagram_session.username && <SocialIcon target="_parent" url={"https://instagram.com/" + profile.instagram_session.username.replace("@", "")} />}
          </div>
        </Col>
      </Row>}
      <Row className="my-4">
        <Col>
          <Button className="float-right" variant="primary"><a href={storeUrl} target="_parent">View in {isAndroid ? "Google Play" : "App Store"}</a></Button>
        </Col>
        <Col>
          <Button className="float-left" variant="primary"><a href={"artistconnect://user/" + alias} target="_parent">Open in Artist Connect</a></Button>
        </Col>
      </Row>
    </Container>
  );
}

export default withFirebase(Profile);