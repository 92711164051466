import { Button, Card, Jumbotron } from 'react-bootstrap';
import { isAndroid, isIOS } from "react-device-detect";
import { withRouter } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import config from '../config';

const Home = ({ history }) => {

  const [userInfo, setUserInfo] = useState(null);

  const isProfile = window.location.pathname?.length > 0 && window.location.pathname !== '/' && window.location.pathname !== '/jobs';

  if ((isAndroid || isIOS) && isProfile) {
    window.location = "artistconnect://user" + window.location.pathname;
  } else if (!isProfile) {
    history.push('/jobs');
  }

  useEffect(() => {
    (async () => {
      try {
        if (isProfile) {
          const resp = await fetch(config.ApiPublicUrl + `/profile` + window.location.pathname);
          const info = await resp.json();
          setUserInfo(info);
        }
      } catch (error) {
        console.log(error);
      }
    })()
  }, []);

  const text = isAndroid ? 'Get Artist Connect on Google Play' : 'Get Artist Connect on the App Store';
  const url = isAndroid ? 'https://play.google.com/store/apps/details?id=com.kingsagemusic.ArtistConnect&hl=en_US&pageId=none' : 'https://apps.apple.com/us/app/artist-connect/id1203809730';

  return (
    <>
    <Jumbotron className="vertical-center">
      <Card className="shadow mx-auto">
        <Card.Body>
          <Card.Text>{text}</Card.Text>
          <Button variant="primary" onClick={() => window.location = url}>Go!</Button>
        </Card.Body>
      </Card>
    </Jumbotron>
    </>
  );
}

export default withRouter(Home);