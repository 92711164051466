import React from 'react';
import Modal from 'react-modal';

const customStyles = {
  content: {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    minWidth             : '40%',
    // height                : '60%',
  },
  button: {
    float: 'right'
  }
};

Modal.setAppElement(document.getElementById('root'));
 
class ModalBox extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    
  }

  componentWillUnmount() {
    
  }

  afterOpenModal() {
    
  }

  render() {
    let Component = this.props.component;
    return (
      <Modal
        isOpen={this.props.isOpen}
        onAfterOpen={this.afterOpenModal}
        onRequestClose={this.props.onClose}
        style={customStyles}
        contentLabel={this.props.title}
      >
        <Component {...this.props} />
      </Modal>
    );
  }
};
 
export default ModalBox;
