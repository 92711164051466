import { Col, Container, Jumbotron, Nav, Row, Spinner } from 'react-bootstrap';
import { compose } from 'recompose';
import React, { useState, useEffect } from 'react';

import { withAuthorization } from '../Session';
import { withFirebase } from '../Firebase';
import ConnectTile from '../ConnectTile';

const Jobs = ({ firebase }) => {
  const [profile, setProfile] = useState(null);
  const [connects, setConnects] = useState(null);
  const [genres, setGenres] = useState(null);
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    if (showLoader) {
      setTimeout(() => {
        setShowLoader(false);
      }, 600);
    }

    firebase.currentProfile().once('value', (snapshot) => {
      setProfile(snapshot.val());
    });

    firebase.genres().once('value', (snapshot) => {
      setGenres(snapshot.val());
    });

    firebase
      .finalizedConnects(firebase.authUser().uid)
      .then(connects => setConnects(connects.sort((a, b) => b.state_changelog.finalized - a.state_changelog.finalized)))
      .catch(error => console.log(error));
  }, []);

  if (showLoader || !connects) {
    return (
      <Jumbotron className="vertical-center">
        <Spinner
          className="justify-content-center mx-auto"
          style={{ width: '3rem', height: '3rem' }}
          variant="primary" animation="border" />
      </Jumbotron>
    );
  }

  return (
    <Container>
      <Row>
        <Col lg={12} md={12} xs={12}>
          <Nav style={{ float: 'left', padding: '10px' }}>
            <Nav.Item>
              <Nav.Link href="https://artistconnectapp.com">Artist Connect</Nav.Link>
            </Nav.Item>
          </Nav>
          <Nav style={{ float: 'right', padding: '10px' }}>
            <Nav.Item>
              <Nav.Link as="span">Logged in as {profile?.alias}</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link onClick={() => firebase.doSignOut()}>Sign Out</Nav.Link>
            </Nav.Item>
          </Nav>
        </Col>
      </Row>
      <Row>
        {(connects || []).map(connect => (
          <Col key={connect.id} xs={12} md={4}>
            <ConnectTile connect={connect} genres={genres} />
          </Col>
        ))}
      </Row>
    </Container>
  );
}

export default compose(
  withAuthorization,
  withFirebase,
)(Jobs);