import '../firebaseui-styling.global.css';
import './index.css';

import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Jumbotron,
  Nav,
  OverlayTrigger,
  ProgressBar,
  Row,
  Spinner,
  Tooltip
} from 'react-bootstrap';
import { FileImage, FilePlay, FileMusic, FileText, FileZip, FileBinary, X } from 'react-bootstrap-icons';
import { compose } from 'recompose';
import { useParams } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import * as moment from 'moment-timezone';

import { withAuthorization } from '../Session';
import { withFirebase } from '../Firebase';
import Loading from '../Loading';
import Modal from '../Modal';
import NotFound from '../NotFound';
import ProfileImage from '../ProfileImage';
import UserSearch from '../UserSearch';
import config from '../config';

const MaxFileSizeBytes = 2 * 1024 * 1024 * 1024;

function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

const Connect = ({ firebase }) => {

  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setShowLoader(false);
    }, 600);
  }, []);

  if (showLoader) {
    return (
      <Jumbotron className="vertical-center">
        <Spinner
          className="justify-content-center mx-auto"
          style={{ width: '3rem', height: '3rem' }}
          variant="primary" animation="border" />
      </Jumbotron>
    );
  }

  return (
    <LoggedIn user={firebase.authUser()} firebase={firebase} />
  );
}

const ConnectDetail = ({ firebase, profile, otherUser, connect, genres, onRemoveUser }) => {

  const canDelete = firebase.authUser().uid === connect.target_user_id;

  return (
    <Card.Body>
      <Card.Title>Your job with {otherUser.alias}</Card.Title>
      <Card.Subtitle className="mb-2 text-muted">{connect.service.service} &middot; {genres[connect.service.genre_id].name}</Card.Subtitle>
      {connect.authorized_user_ids && <p className="mt-4 h6">Authorized Users</p>}
      {connect.authorized_user_ids && Object.keys(connect.authorized_user_ids).map((userId, index) => (
        <ProfileImage className={index > 0 ? "ml-2" : ""} onClick={onRemoveUser} infoTextFormat={canDelete ? "Remove %@?" : "%@"} key={userId} userId={userId} size={38} showTooltip overlay={canDelete ? X : null} />
      ))}
    </Card.Body>
  );
}

const File = ({ firebase, file, isSelected, onChecked, downloadFile, deleteFile, isDownloadingUrl, isDownloadedUrl, isDeletingUrl, canDelete }) => {
  const [alias, setAlias] = useState(null);

  const tz = moment.tz.guess();
  const date = moment.tz(file.timeCreated, tz);
  const now = moment.tz(new Date(), tz);
  let format;

  useEffect(() => {
    (async () => {
      try {
        if (file.customMetadata.uploadedBy) {
          const profile = await firebase.profile(file.customMetadata.uploadedBy).once("value");
          setAlias(profile.val()?.alias);
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  if (date.isSame(now, 'day')) {
    format = '[Today at] h:mma';
  } else if (date.isSame(now.subtract(1, 'days'), 'day')) {
    format = '[Yesterday at] h:mma';
  } else if (date.isBetween(now, now.subtract(7, 'days'))) {
    format = 'dddd [at] h:mma';
  } else if (date.isSame(now, 'year')) {
    format = 'MMM Do [at] h:mma';
  } else {
    format = 'MMM Do YYYY [at] h:mma';
  }

  let FileIcon;
  let contentType = file.contentType;
  if (contentType.startsWith('image/')) {
    FileIcon = FileImage;
  } else if (contentType.startsWith('video/')) {
    FileIcon = FilePlay;
  } else if (contentType.startsWith('audio/')) {
    FileIcon = FileMusic;
  } else if (contentType.startsWith('text/') || contentType === 'application/msword' || contentType === 'application/json' || contentType === 'application/pdf' || contentType === 'application/rtf') {
    FileIcon = FileText;
  } else if (contentType === 'application/gzip' || contentType === 'application/zip' || contentType === 'application/vnd.rar' || contentType === 'application/x-tar' || contentType === 'application/x-7z-compressed') {
    FileIcon = FileZip;
  } else {
    FileIcon = FileBinary;
  }

  return (
    <Row noGutters className="file pt-2 pb-2">
      <Col xs="auto" className="mr-3">
        {/*<Form.Check
          className="mt-2"
          type="checkbox"
          aria-label="Select file"
          checked={isSelected}
          onChange={e => onChecked(e.target.checked)} />*/}
        <FileIcon className="mt-1" size={18} />
      </Col>
      <Col>
        <p className="mb-0">{file.customMetadata.connectFileName || file.name} <small className="ml-1 text-muted">({formatBytes(file.size)})</small></p>
        <small className="text-muted">{file.customMetadata.connectFileDescription}</small>
      </Col>
      <Col xs={12} md="auto">
        <OverlayTrigger
          placement='top'
          overlay={
            <Tooltip>
              {date.format("dddd, MMMM Do YYYY, h:mm:ss a")} ({date.fromNow()})
            </Tooltip>
          }
        >
          <small className="d-block text-right">Uploaded {alias ? `by ${alias}, ` : ""}{date.format(format)}</small>
        </OverlayTrigger>
        {!isDeletingUrl && canDelete && <Button className="download-btn d-block float-right" variant="link" onClick={() => deleteFile(file)}>Delete</Button>}
        {isDeletingUrl && canDelete && <Spinner className="float-right ml-4 mt-2 mb-3" size="sm" animation="border" />}
        {!isDownloadingUrl && <Button className="download-btn d-block float-right" variant="link" onClick={() => downloadFile(file)}>{isDownloadedUrl ? 'Downloading...' : 'Download'}</Button>}
        {isDownloadingUrl && <Spinner className="float-right mr-4 mt-2 mb-3" size="sm" animation="border" />}
      </Col>
    </Row>
  );
}

const FileList = ({ firebase, connect }) => {

  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [files, setFiles] = useState([]);
  const [selectedIndices, setSelectedIndices] = useState([]);
  const [downloadingUrlIndex, setDownloadingUrlIndex] = useState(-1);
  const [downloadedUrlIndex, setDownloadedUrlIndex] = useState(-1);
  const [deletingUrlIndex, setDeletingUrlIndex] = useState(-1);

  const canUpload = firebase.authUser().uid === connect.target_user_id || firebase.authUser().uid === connect.source_user_id || Object.keys(connect.authorized_user_ids || {}).indexOf(firebase.authUser().uid) > -1;
  const hasFiles = files && files.length > 0;

  useEffect(() => {
    reloadFiles();
  }, []);

  function reloadFiles() {
    firebase
      .listConnectFiles({ connect })
      .then(refs => Promise.all(refs.map(ref => ref.getMetadata())))
      .then(files => setFiles(files.sort((a, b) => moment(b.timeCreated).unix() - moment(a.timeCreated).unix())))
      .catch(error => console.log(JSON.stringify(error)));
  }

  function onCheckedFile(index, file, checked) {
    const indices = selectedIndices.slice();

    if (checked === true && indices.indexOf(index) === -1) {
      indices.push(index);
    } else if (checked === false && indices.indexOf(index) > -1) {
      indices.splice(indices.indexOf(index), 1);
    }

    setSelectedIndices(indices);
  }

  function onDeleteFile(file) {
    if (window.confirm('Are you sure you want to delete ' + file.customMetadata.connectFileName || file.name + '?')) {
      const index = files.indexOf(file)
      setDeletingUrlIndex(index);

      firebase
        .deleteConnectFile(file)
        .then(url => {
          setDeletingUrlIndex(-1);
          reloadFiles()
        })
        .catch(error => {
          setDeletingUrlIndex(-1);
          alert(error.message);
        });
    }
  }

  function onDownloadFile(file) {
    setDownloadingUrlIndex(files.indexOf(file));

    firebase
      .downloadConnectFile(file)
      .then(url => {
        setDownloadingUrlIndex(-1);
        setDownloadedUrlIndex(files.indexOf(file));
        window.location = url;
        setTimeout(() => {
          setDownloadedUrlIndex(-1);
        }, 1000);
      })
      .catch(error => {
        setDownloadingUrlIndex(-1);
        alert(error.message);
      });
  }

  return (
    <Card.Body>
      <Card.Title className="mb-2">Attached Files</Card.Title>
      <Container className="mb-4 mt-4" fluid>
        {hasFiles ? files.map((file, index) => (
          <File key={index} file={file} firebase={firebase} canDelete={canUpload} isDeletingUrl={deletingUrlIndex === index} isDownloadedUrl={downloadedUrlIndex === index} isDownloadingUrl={downloadingUrlIndex === index} isSelected={selectedIndices.indexOf(index) > -1} downloadFile={onDownloadFile} deleteFile={onDeleteFile} onChecked={checked => onCheckedFile(index, file, checked)} />
        )) : <div className="text-center text-muted mb-4"><i>0 files are attached.</i></div>}
      </Container>
      {canUpload && <Button className="mr-2" block={!hasFiles} variant="primary" onClick={() => setIsUploadModalOpen(true)}>Add a file</Button>}
      {/*(hasFiles && canUpload) && <Button onClick={deleteFiles} disabled={selectedIndices.length === 0} variant="danger">Delete{selectedIndices.length > 0 ? ` ${selectedIndices.length}` : ''} File{selectedIndices.length > 1 ? 's' : ''}</Button>*/}
      <Modal
        title="Upload File"
        isOpen={isUploadModalOpen}
        onClose={() => setIsUploadModalOpen(false)}
        connect={connect}
        firebase={firebase}
        onUpload={reloadFiles}
        component={UploadModal} />
    </Card.Body>
  );
}

const UploadModal = ({ firebase, connect, onClose, onUpload }) => {

  const [selectedFile, setSelectedFile] = useState(null);
  const [fileName, setFileName] = useState('');
  const [fileDesc, setFileDesc] = useState('');
  const [uploadState, setUploadState] = useState({});
  const [uploadTask, setUploadTask] = useState(null);

  function fileSelected(file) {
    if (file) {
      if (file.size > MaxFileSizeBytes) {
        alert('File exceeds maximum size of 2 GB.');
        return;
      }
    }

    const previousDefaultName = selectedFile?.name.split('.')[0];
    const defaultName = file?.name.split('.')[0];

    if (!fileName || fileName === '' || fileName === previousDefaultName) {
      setFileName(defaultName);
    }

    setSelectedFile(file);
  }

  function onCancel() {
    uploadTask.cancel();
    setUploadTask(null);
    setUploadState({});
  }

  function onBack() {
    setUploadTask(null);
    setUploadState({});
  }

  function onFormSubmit(event) {
    event.preventDefault();
    
    if (selectedFile) {
      if (selectedFile.size > MaxFileSizeBytes) {
        alert('File exceeds maximum size of 2 GB.');
        return;
      }
    } else {
      alert('You need to select a file to upload.');
      return;
    }

    const uploadTask = firebase
      .uploadConnectFile({
        connect,
        file: selectedFile,
        name: fileName,
        description: fileDesc,
      });
    setUploadTask(uploadTask);

    uploadTask.on('state_changed', 
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log('Upload is ' + progress + '% done', snapshot.state);
        setUploadState({ progress, state: snapshot.state });
      }, 
      (error) => {
        console.log(error);
        setUploadTask(null);
        if (error.code !== 'storage/canceled') {
          setUploadState({ error });
        }
      }, 
      () => {
        setUploadTask(null);
        setUploadState({ success: true });
        onUpload();
        console.log({
                  size: selectedFile.size,
                  customMetadata: {
                    connectFileName: fileName,
                    connectFileDesc: fileDesc
                  }
                });
        firebase
          .auth
          .currentUser
          .getIdToken()
          .then(token => {
            return fetch(
              config.ApiUrl + `/connect/${connect.id}/file`,
              {
                method: 'PUT',
                headers: {
                  'Authorization': "Bearer " + token,
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                  file: {
                    size: selectedFile.size,
                    customMetadata: {
                      connectFileName: fileName,
                      connectFileDesc: fileDesc
                    }
                  }
                })
              }
            )
          })
          .catch(error => console.log(error));
        console.log('completed upload');
      }
    );
  }

  const showForm = !uploadState.state && !uploadState.error && !uploadState.success;

  return (
    <>
    <h4 className="mb-4 mr-2 d-inline">{!showForm ? `Uploading ${selectedFile.name}` : 'Upload a file'}</h4>
    {showForm && <small className="text-muted d-inline">(max 2 GB)</small>}
    {showForm && <Form onSubmit={onFormSubmit} className="m-3">
      <Form.File
        id="file"
        className="mb-3"
        label={selectedFile?.name || "Choose a file"}
        onChange={event => fileSelected(event.target.files[0])}
        custom
      />
      <Form.Group controlId="uploadForm.fileName">
        <Form.Label>Enter a name</Form.Label>
        <Form.Control type="text" value={fileName} onChange={event => setFileName(event.target.value)} />
      </Form.Group>
      <Form.Group controlId="uploadForm.fileDesc">
        <Form.Label>Enter a short description <small className="ml-1 text-muted">optional</small></Form.Label>
        <Form.Control as="textarea" rows={2} value={fileDesc} onChange={event => setFileDesc(event.target.value)} />
      </Form.Group>

      <Button className="mt-3 mr-3" variant="primary" type="submit">
        Upload
      </Button>
      <Button className="mt-3" variant="outline-danger" onClick={onClose}>Cancel</Button>
    </Form>}
    {uploadState.state && <ProgressBar className="mt-4" animated={true} label={`${Math.round(uploadState.progress)}%`} now={uploadState.progress} />}
    {uploadState.state && <Button className="mt-4" variant="danger" onClick={onCancel}>Cancel</Button>}
    {uploadState.success && <ProgressBar className="mt-4" animated={false} variant="success" label="Complete" now={100} />}
    {uploadState.success && <Button className="mt-4" variant="primary" onClick={onClose}>Close</Button>}
    {uploadState.error && <ProgressBar className="mt-4" animated={false} variant="danger" label="An error occurred!" now={100} />}
    {uploadState.error && <p className="mt-3">{uploadState.error.message}</p>}
    {uploadState.error && <Button className="mt-4 mr-3" variant="primary" onClick={onBack}>Back</Button>}
    {uploadState.error && <Button className="mt-4" variant="secondary" onClick={onClose}>Close</Button>}
    </>
  );
}

const LoggedIn = ({ firebase, user }) => {
  const { connectId } = useParams();
  const [connect, setConnect] = useState(null);
  const [otherUser, setOtherUser] = useState(null);
  const [profile, setProfile] = useState(null);
  const [genres, setGenres] = useState(null);

  const canUpload = firebase.authUser().uid === connect?.target_user_id;

  useEffect(() => {
    firebase.genres().once('value', (snapshot) => {
      setGenres(snapshot.val());
    });

    firebase.connect(connectId).on('value', (connectSnapshot) => {
      if (!connectSnapshot.exists()) {
        setConnect({ notFound: true });
        return;
      }

      const updatedConnect = connectSnapshot.val();
      setConnect(updatedConnect);

      if (updatedConnect) {
        const otherUserId = updatedConnect.source_user_id === user.uid ? updatedConnect.target_user_id : updatedConnect.source_user_id;
        firebase.profile(otherUserId)
          .once('value')
          .then((otherUserSnapshot) => setOtherUser(otherUserSnapshot.val()))
          .catch(console.log);
      }
    });
    
    firebase.profile(user.uid).on('value', (snapshot) => {
      setProfile(snapshot.val());
    });
  }, []);

  function onAddUser(user) {
    if (window.confirm(`Are you sure you want to add ${user.alias} to this job?`)) {
      firebase.authUser().getIdToken()
        .then(token => {
          return fetch(
            config.ApiUrl + `/connect/${connect.id}/authorize_user`,
            {
              method: 'PUT',
              headers: {
                'Authorization': "Bearer " + token,
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                userId: user.user_id
              })
            }
          )
        })
        .catch(error => alert(error.message));
    }
  }

  function onRemoveUser(user) {
    if (window.confirm(`Are you sure you want to remove ${user.alias} from this job?`)) {
      firebase.authUser().getIdToken()
        .then(token => {
          return fetch(
            config.ApiUrl + `/connect/${connect.id}/authorize_user/${user.user_id}`,
            {
              method: 'DELETE',
              headers: {
                'Authorization': "Bearer " + token,
                'Content-Type': 'application/json'
              },
            }
          )
        })
        .catch(error => alert(error.message));
    }
  }

  if (connect && ((connect.target_user_id !== user.uid && connect.source_user_id !== user.uid && Object.keys(connect.authorized_user_ids || []).indexOf(user.uid) === -1) || connect.notFound === true || connect.state !== 'finalized')) {
    return <NotFound/>
  }

  return (
    <Container className="mb-4">
      <Row>
        <Col lg={12} md={12} xs={12}>
          <Nav style={{ float: 'left', padding: '10px' }}>
            <Nav.Item>
              <Nav.Link href="https://artistconnectapp.com">Artist Connect</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="/jobs">All Jobs</Nav.Link>
            </Nav.Item>
          </Nav>
          <Nav style={{ float: 'right', padding: '10px' }}>
            <Nav.Item>
              <Nav.Link as="span">Logged in as {profile?.alias}</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link onClick={() => firebase.doSignOut()}>Sign Out</Nav.Link>
            </Nav.Item>
          </Nav>
        </Col>
      </Row>
      <Row>
        <Col lg={4} md={4} xs={12}>
          <Card className="shadow" style={{ marginBottom: '30px' }}>
            <Loading component={ConnectDetail} onRemoveUser={onRemoveUser} firebase={firebase} genres={genres} profile={profile} connect={connect} otherUser={otherUser} isLoaded={profile && connect && otherUser && genres} />
          </Card>
          {canUpload && <Card className="shadow" style={{ marginBottom: '30px' }}>
            <Card.Body>
              <Card.Title>Add another user</Card.Title>
              <UserSearch onSelect={onAddUser} />
            </Card.Body>
          </Card>}
        </Col>
        <Col lg={8} md={8} xs={12}>
          <Card className="shadow">
            <Loading component={FileList} firebase={firebase} connect={connect} isLoaded={connect} />
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default compose(
  withAuthorization,
  withFirebase,
)(Connect);
