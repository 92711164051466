import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';

import { v4 as uuidv4 } from 'uuid';
import * as pp from 'path';

import config from '../config';

class Firebase {
  constructor() {
    app.initializeApp({
      apiKey: config.ApiKey,
      authDomain: config.AuthDomain,
      databaseURL: config.DatabaseUrl,
      projectId: config.ProjectId,
      storageBucket: config.StorageBucket,
      messagingSenderId: config.MessagingSenderId,
      appId: config.AppId,
    });

    this.auth = app.auth();
    this.db = app.database();
    this.storage = app.storage();
  }

  // *** Auth API ***
 
  doSignOut = () => this.auth.signOut();

  authUser = () => {
    return this.auth.currentUser;
  }

  // *** Search API ***

  findUser = (query) => {
    const ref = this.db
    .ref('search/request')
    .push()

    return ref
      .set({
        index: 'firebase',
        size: 25,
        type: 'profile',
        body: {
          query: {
            bool: {
              must: [{
                wildcard: {
                  alias: `*${query.toLowerCase()}*`
                }
              }]
            }
          }
        }
      })
      .then(() => {
        return new Promise((resolve, reject) => {
          const respRef = this.db.ref(`search/response/${ref.key}`);

          respRef
            .on('value', (snapshot) => {
              if (snapshot.exists()) {
                resolve(snapshot.val())
                respRef.off('value');
              }
            });
        })
      })
      .then(response => {
        return this.db
          .ref(`search/response/${ref.key}`)
          .remove()
          .then(() => Promise.all(response?.hits?.hits?.map(hit => this.profile(hit._id).once('value').then(snap => snap.val())) || []));
      })
  }

  // *** User API ***
 
  currentUser = () => this.db.ref(`users/${this.authUser().uid}`);

  // *** Profile API ***
 
  currentProfile = () => this.db.ref(`profiles/${this.authUser().uid}`);
  profile = uid => this.db.ref(`profiles/${uid}`);
  finalizedConnects = uid => this.db
    .ref(`profiles/${uid}`)
    .once('value')
    .then(snapshot => {
      const profile = snapshot.val();
      const incomingConnectIds = Object.keys(profile.incoming_connect_ids || {})
        .filter(connectId => profile.incoming_connect_ids[connectId] === 'finalized');
      const outgoingConnectIds = Object.keys(profile.outgoing_connect_ids || {})
        .filter(connectId => profile.outgoing_connect_ids[connectId] === 'finalized');
      const authorizedConnectIds = Object.keys(profile.authorized_connect_ids || {});
      const finalizedConnectIds = incomingConnectIds.concat(outgoingConnectIds).concat(authorizedConnectIds);

      return Promise.all(finalizedConnectIds.map(connectId => this.connect(connectId).once('value').then(snap => snap.val())));
    });

  // *** Connects API ***
 
  genres = () => this.db.ref(`genres`);
  connect = id => this.db.ref(`connects/${id}`);

  // *** Connect Files API ***

  uploadConnectFile = ({ connect, file, name, description }) => this.storage
    .ref(`connect_files/${connect.id}/${this.authUser().uid}`)
    .child(`${uuidv4()}${pp.extname(file.name)}`)
    .put(file, {
      contentType: file.type,
      customMetadata: {
        connectFileName: name,
        connectFileDescription: description,
        connectId: connect.id,
        uploadedBy: this.authUser().uid,
        targetUserId: connect.target_user_id,
        sourceUserId: connect.source_user_id,
      }
    });

  listConnectFiles = ({ connect }) => this.storage
    .ref('connect_files')
    .child(connect.id)
    .listAll()
    .then(res => Promise.all(res.prefixes.map(ref => ref.listAll())))
    .then(refs => refs.flatMap(ref => ref.items));

  downloadConnectFile = file => this.storage
    .ref(file.fullPath)
    .getDownloadURL();

  deleteConnectFile = file => this.storage
    .ref(file.fullPath)
    .delete();
}
 
export default Firebase;
