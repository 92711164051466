import { Image, OverlayTrigger, Tooltip } from 'react-bootstrap';
import React, { useState, useEffect } from 'react';

import { withFirebase } from '../Firebase';

import './index.css';

const ProfileImage = ({ className, firebase, profile, userId, size, showTooltip, overlay, infoTextFormat, onClick }) => {
  const [fetchedProfile, setFetchedProfile] = useState(profile);
  const [imageUrl, setImageUrl] = useState('/logo192.png');

  useEffect(() => {
    if (fetchedProfile && fetchedProfile.profile_image_urls && fetchedProfile.profile_image_urls.length > 0) {
      firebase
        .storage
        .refFromURL(fetchedProfile.profile_image_urls[0])
        .getDownloadURL()
        .then(setImageUrl)
        .catch(error => console.log(error));
    } else if (userId && userId.length > 0) {
      firebase
        .profile(userId)
        .once('value')
        .then(snapshot => {
          setFetchedProfile(snapshot.val());
        })
        .catch(error => console.log(error));
    }
  }, [fetchedProfile]);

  const text = infoTextFormat ? infoTextFormat.replace("%@", fetchedProfile?.alias || "") : fetchedProfile?.alias;

  const OverlayComponent = overlay;
  const ImageElement = (
    <div className={"profile-image d-inline-block "+className} onClick={() => onClick(fetchedProfile)}>
      {overlay && <div className="profile-image-overlay"><OverlayComponent className="profile-image-delete-icon" size={(size || 64) * 0.8} /></div>}
      <Image style={{ width: `${size || 64}px`, height: `${size || 64}px`, borderRadius: '50%' }} src={imageUrl} />
    </div>
  );

  if (showTooltip) {
    return (
      <OverlayTrigger
        placement='bottom'
        overlay={
          <Tooltip>
            {text}
          </Tooltip>
        }
      >
        {ImageElement}
      </OverlayTrigger>
    );
  } else {
    return ImageElement;
  }
}

export default withFirebase(ProfileImage);