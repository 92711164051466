import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import React from 'react';

import Connect from '../Connect';
import Profile from '../Profile';
import Home from '../Home';
import Privacy from '../Privacy';
import Jobs from '../Jobs';

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/c/:connectId">
          <Connect />
        </Route>   
        <Route path="/u/:alias">
          <Profile />
        </Route>   
        <Route path="/s/privacy">
          <Privacy />
        </Route>  
        <Route path="/jobs">
          <Jobs />
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
