import './index.css';

import { Card, Col, Container, Row, Spinner } from 'react-bootstrap';
import { compose } from 'recompose';
import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import * as moment from 'moment-timezone';

import { withFirebase } from '../Firebase';
import ProfileImage from '../ProfileImage';

const ConnectTile = ({ history, firebase, connect, genres }) => {
  const [profile, setProfile] = useState(null);
  const [otherProfile, setOtherProfile] = useState(null);

  const otherUserId = connect.source_user_id === firebase.auth.currentUser.uid ? connect.target_user_id : connect.source_user_id;
  const otherOtherUserId = connect.source_user_id === firebase.auth.currentUser.uid ? connect.source_user_id : connect.target_user_id;
  const isThirdParty = connect.source_user_id !== firebase.auth.currentUser.uid && connect.target_user_id !== firebase.auth.currentUser.uid;

  useEffect(() => {
    firebase.profile(otherUserId).on('value', (snapshot) => {
      setProfile(snapshot.val())
    });

    if (isThirdParty) {
      firebase.profile(otherOtherUserId).on('value', (snapshot) => {
        setOtherProfile(snapshot.val())
      });
    }
  }, []);

  function openConnect() {
    history.push(`/c/${connect.id}`);
  }

  return (
    <Card className="shadow connect" style={{ marginBottom: '30px' }} onClick={openConnect}>
      {profile && <Card.Body>
        <Container className="p-0" fluid>
          <Row noGutters>
            <Col xs={2}>
              <ProfileImage profile={profile} />
            </Col>
            <Col className="ml-4 pl-1">
              <p className="mb-0 h6">{isThirdParty ? `${profile?.alias}'s job with ${otherProfile?.alias}` : `Your job with ${profile.alias}`}</p>
              <p className="mb-0 text-muted">{connect.service.service} &middot; {genres[connect.service.genre_id].name}</p>
              <p className="mb-0 text-muted"><small>{moment(connect.state_changelog.finalized).fromNow()}</small></p>
            </Col>
          </Row>
        </Container>
      </Card.Body>}
      {!profile && <Spinner
        className="justify-content-center mx-auto my-4"
        style={{ width: '2rem', height: '2rem' }}
        variant="primary" animation="border" />}
    </Card>
  );
}

export default compose(
  withRouter,
  withFirebase,
)(ConnectTile);
