import { Card, Jumbotron } from 'react-bootstrap';
import { StyledFirebaseAuth } from 'react-firebaseui';
import { compose } from 'recompose';
import React from 'react';
import { default as fb } from 'firebase';
import { withFirebase } from '../Firebase';

const SignIn = ({ firebase }) => {
  const uiConfig = {
    signInFlow: 'popup',
    callbacks: {
      signInSuccessWithAuthResult: () => {
        return false;
      }
    },
    signInOptions: [
      fb.auth.PhoneAuthProvider.PROVIDER_ID
    ]
  };

  return (
    <Jumbotron className="vertical-center">
      <Card className="shadow mx-auto">
        <Card.Body>
          <div className="logo-small mx-auto"><img src="/logo192.png" />Artist Connect</div>
          <Card.Title>Login to view your job</Card.Title>
          <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth} />
        </Card.Body>
      </Card>
    </Jumbotron>
  );
}

export default withFirebase(SignIn);