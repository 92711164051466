import { Spinner } from 'react-bootstrap';
import React from 'react';

const Loading = (props) => {

  if (props.isLoaded) {
    return <props.component {...props} />;
  }

  return (
    <div className="vertical-center mx-auto loading" style={{ margin: '20px' }}>
      <Spinner animation="border" role="status">
        <span className="sr-only">Loading...</span>
      </Spinner>
    </div>
  );
}

export default Loading;
