import { compose } from 'recompose';
import React from 'react';

import { withFirebase } from '../Firebase';
import AuthUserContext from './context';
import { withAuthentication } from './index';
import SignIn from '../SignIn';

 
const withAuthorization = Component => {
  class WithAuthorization extends React.Component {
    render() {
      return (
        <AuthUserContext.Consumer>
          {authUser => {
            return authUser === null ? <SignIn /> : <Component {...this.props} />
          }
          }
        </AuthUserContext.Consumer>
      );
    }
  }
 
  return compose(
    withAuthentication,
    withFirebase,
  )(WithAuthorization);
};
 
export default withAuthorization;