
const prod = {
  ApiKey: process.env.REACT_APP_API_KEY,
  AuthDomain: process.env.REACT_APP_AUTH_DOMAIN,
  DatabaseUrl: process.env.REACT_APP_DATABASE_URL,
  ProjectId: process.env.REACT_APP_PROJECT_ID,
  StorageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  MessagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  AppId: process.env.REACT_APP_APP_ID,
  ApiUrl: process.env.REACT_APP_API_URL,
  ApiPublicUrl: process.env.REACT_APP_API_PUBLIC_URL,
};

const staging = {
  ApiKey: process.env.REACT_APP_API_KEY_STAGING,
  AuthDomain: process.env.REACT_APP_AUTH_DOMAIN_STAGING,
  DatabaseUrl: process.env.REACT_APP_DATABASE_URL_STAGING,
  ProjectId: process.env.REACT_APP_PROJECT_ID_STAGING,
  StorageBucket: process.env.REACT_APP_STORAGE_BUCKET_STAGING,
  MessagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID_STAGING,
  AppId: process.env.REACT_APP_APP_ID_STAGING,
  ApiUrl: process.env.REACT_APP_API_URL_STAGING,
  ApiPublicUrl: process.env.REACT_APP_API_PUBLIC_URL_STAGING,
};

const config = process.env.REACT_APP_STAGE === 'staging' ? staging : prod;

export default config;
